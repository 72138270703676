import {createRouter, createWebHistory} from 'vue-router';

const routes = [
    {
        path: '/',
        redirect: '/collections',
        meta: {
            title: 'Collections',
        },
    },
    {
        path: '/collections',
        name: 'Collections',
        component: () => import('../views/Collections.vue'),
        meta: {
            title: 'Collections',
            showNavigation: true,
        },
        //meta: { requiresAuth: true }
    },
    {
        path: '/goddesses',
        name: 'Goddesses',
        component: () => import('../views/Goddesses.vue'),
        meta: {
            title: 'Goddesses',
            showNavigation: true,
        },
    },
    {
        path: '/goddesses/:goddess_id',
        name: 'Goddess collections',
        component: () => import('../views/GoddessCollections.vue'),
        meta: {
            title: 'Goddesses'
        }
    },
    {
        path: '/goddesses/:goddess_id/:collection_id',
        name: 'Goddess photos',
        component: () => import('../views/Photos.vue'),
        meta: {
            title: 'Goddesses'
        }
    },
    {
        path: '/collections/:collection_id',
        name: 'Collection photos',
        component: () => import('../views/Photos.vue'),
        meta: {
            title: 'Collections'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
        meta: {
            title: 'Login'
        }
    },
    {
        path: '/signup',
        name: 'Sign up',
        component: () => import('../views/Registration.vue'),
        meta: {
            title: 'Sign up'
        }
    },
    {
        path: '/forgotPassword',
        name: 'Password recovery',
        component: () => import('../views/ForgotPassword.vue'),
        meta: {
            title: 'Forgot password'
        }
    },
    {
        path: '/resetPassword',
        name: 'Reset password',
        component: () => import('../views/ResetPassword.vue'),
        meta: {
            title: 'Reset password'
        }
    },
    {
        path: '/contactme',
        name: 'Contact me',
        component: () => import('../views/Contact.vue'),
        meta: {
            title: 'Contact me'
        }
    },
    {
        path: '/upload-photos',
        name: 'Nahratie fotiek',
        component: () => import('../views/admin/UploadPhotos.vue'),
        meta: {
            title: ' Upload photos',
            showAdminNavigation: true,
        },
    },
    {
        path: '/admin/goddesses',
        name: 'Goddesses admin',
        component: () => import('../views/admin/Goddesses.vue'),
        meta: {
            title: 'Goddesses',
            showAdminNavigation: true,
        },
    },
    {
        path: '/admin/collections/:goddess_id',
        name: 'Collections admin',
        component: () => import('../views/admin/Collections.vue'),
        meta: {
            title: 'Collections',
            showAdminNavigation: true,
        },
    },
    {
        path: '/admin/photos/:collection_id',
        name: 'Photos admin',
        component: () => import('../views/admin/Photos.vue'),
        meta: {
            title: 'Photos',
            showAdminNavigation: true,
        },
    },
    {
        path: '/admin/users',
        name: 'Users admin',
        component: () => import('../views/admin/Users.vue'),
        meta: {
            title: 'Users',
            showAdminNavigation: true,
        },
    },
    {
        path: '/admin/settings',
        name: 'Settings admin',
        component: () => import('../views/admin/Settings.vue'),
        meta: {
            title: 'Settings',
            showAdminNavigation: true,
        },
    },
    {
        path: '/payment',
        name: 'Payment',
        component: () => import('../views/Payment.vue'),
        mety: {
            title: 'Payment'
        }
    },
    {
        path: '/subscription',
        name: 'Subscription',
        component: () => import('../views/Subscription.vue'),
        meta: {
            title: 'Subscription'
        }
    },
    {
        path: '/search',
        name: 'Search',
        component: () => import('../views/Search.vue'),
        meta: {
            title: 'Search'
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    const defaultTitle = '';
    document.title = to.meta.title || defaultTitle;
    next();
});

/*
router.beforeEach((to, from, next) => {
  // Check if the route requires authentication and if the user is not authenticated
  if (to.matched.some(record => record.meta.requiresAuth) && !AuthService.isAuthenticated()) {
    next({ name: 'Prihlasenie' }); // Redirect to login page
  } else {
    next(); // Allow access to the route
  }
});
*/
export default router
