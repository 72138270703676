import ajax from "@/plugins/api";

function checkExistUser(param,type){
    let url = '';
    if(type === 'base' || type === 'gmail'){
        url = '/user-exist?email='+param;
    }else{
        url = '/user-exist?telegram='+param;
    }
    url += '&type='+type;
    return ajax.get(url).then(response => {
        return response.data;
    }).catch(error => {
            console.log(error);
            return {};
        }
    )
}

function getUsers() {
    return ajax.get('/users', {}).then(response => {
        return response.data;
    }).catch(error => {
            console.log(error);
            return {};
        }
    )
}

function deleteUser(userID) {
    return ajax.post('/delete-user', {
        'userID': userID,
    }).then(response => {
        return response.data;
    }).catch(error => {
            console.log(error);
            return {};
        }
    )
}

function loginUser(email, password) {
    return ajax.post('/login', {email: email, password: password}).then(response => {
        if (response.data.success) {
            setLoginData(response.data);
        }
        return response.data;
    }).catch(error => {
            //alert('Mrzí nás to, ale došlo k chybe. Pracujeme na jej odstránení, skúste to, prosím, neskôr.');
            console.log(error);
            return false
        }
    )
}

function registerUser(email, password) {
    return ajax.post('/registration', {email: email, password: password}).then(response => {
        if (response.data.success) {
            setLoginData(response.data);
        }
        return response.data;
    }).catch(error => {
            //alert('Mrzí nás to, ale došlo k chybe. Pracujeme na jej odstránení, skúste to, prosím, neskôr.');
            console.log(error);
            return {};
        }
    )
}

function registerGmail(email, authToken) {
    return ajax.post('/registration-gmail', {email: email, authToken: authToken}).then(response => {
        if (response.data.success) {
            setLoginData(response.data);
        }
        return response.data;
    }).catch(error => {
            //alert('Mrzí nás to, ale došlo k chybe. Pracujeme na jej odstránení, skúste to, prosím, neskôr.');
            console.log(error);
            return {};
        }
    )
}

function changePassword(oldPassword, newPassword) {
    return ajax.post('/change-password', {oldPassword: oldPassword, newPassword: newPassword}).then(response => {
        return response.data;
    }).catch(error => {
            console.log(error);
            return {};
        }
    )
}

function forgotPassword(email) {
    return ajax.post('/forgot-password', {email: email}).then(response => {
        return response.data;
    }).catch(error => {
            console.log(error);
            return {};
        }
    )
}

function resetPassword(token, password) {
    return ajax.post('/reset-password', {'token': token, 'password': password}).then(response => {
        return response.data;
    }).catch(error => {
            console.log(error);
            return {};
        }
    )
}

function loginGmail(email, accessToken) {
    return ajax.post('/login-gmail', {'email': email, 'token': accessToken}).then(response => {
        if (response.data.success) {
            setLoginData(response.data)
        }
        return response.data;
    }).catch(error => {
            console.log(error);
            return {};
        }
    )
}

function loggedUser() {
    return ajax.get('/logged-user',).then(response => {
        return response.data;
    }).catch(error => {
            console.log(error);
            return {};
        }
    )
}

function setLoginData(data){
    const item = localStorage.getItem('token');
    if (item !== null) {
        localStorage.removeItem('token');
    }
    const user = localStorage.getItem('user');
    if (user !== null) {
        localStorage.removeItem('user');
    }
    localStorage.setItem('user', data.user.email);
    localStorage.setItem('token', data.token);
}

function loginTelegram(username, hash, first_name, last_name,id, auth_date) {
    return ajax.post('/login-telegram', {'first_name':first_name,'last_name':last_name,'telegram_id':id,'auth_date':auth_date,'username': username, 'hash': hash}).then(response => {
        if (response.data.success) {
            setLoginData(response.data)
        }
        return response.data;
    }).catch(error => {
            console.log(error);
            return {};
        }
    )
}

function registerTelegram(username, hash, first_name, last_name,id, auth_date) {
    return ajax.post('/registration-telegram', {'first_name':first_name,'last_name':last_name,'telegram_id':id,'auth_date':auth_date,'username': username, 'hash': hash}).then(response => {
        if (response.data.success) {
            setLoginData(response.data)
        }
        return response.data;
    }).catch(error => {
            console.log(error);
            return {};
        }
    )
}


export {
    getUsers,
    deleteUser,
    loginUser,
    registerUser,
    changePassword,
    forgotPassword,
    resetPassword,
    loginGmail,
    loginTelegram,
    loggedUser,
    registerGmail,
    registerTelegram,
    checkExistUser
}