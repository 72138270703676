import { createApp, ref } from 'vue';
import App from './App.vue';
import router from "@/router";
import './assets/css/main.css';

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

const app = createApp(App);
app.config.globalProperties.$storageUrl = 'https://adampapp.sk/storage/';
//app.config.globalProperties.$storageUrl = 'http://portfolio.local/storage/';
app.config.globalProperties.isLoggedIn = ref(false);

app.directive('disable-right-click', {
    beforeMount(el) {
      // Use the contextmenu event to disable right-click
      el.addEventListener('contextmenu', function (e) {
        e.preventDefault();  // Prevent the default right-click menu
        //console.log('Right click disabled on image');
        return false;
      });
    }
});
app.use(VueViewer)
app.use(router).mount('#app');
