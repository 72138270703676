<template>
    <component :is="currentComponent"></component>
    <router-view></router-view>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import AdminNavigation from "./components/AdminNavigation.vue";

export default {
    name: 'App',
    components: {
        Navigation,
        AdminNavigation
    },
    computed: {
        currentComponent() {
            if (this.$route.meta.showAdminNavigation) {
                return 'AdminNavigation';
            }
            return 'Navigation';

        }
    }
}
</script>