<template>
    <div v-if="$route.meta.showNavigation !== true" class="nav-box">
        <img src="@/assets/images/logo.jpg" alt="logo">
        <RouterLink
            to="/collections"
            activeClass="active-link"
            exactActiveClass="nonactive-link"
            :class="{ 'active-link': isActiveCollections }">
            <p>Collections</p>
        </RouterLink>
        <RouterLink
            to="/goddesses"
            activeClass="active-link"
            exactActiveClass="nonactive-link"
            :class="{ 'active-link': isActiveGoddesses }">
            <p>Goddesses</p>
        </RouterLink>
        <RouterLink
            to="/contactme"
            activeClass="active-link"
            exactActiveClass="nonactive-link">
            <p>Contact me</p>
        </RouterLink>

        <div class="nav-right-buttons-box">
            <button class="search-button button" @click="redirectSearch"><i class="bi bi-search"></i></button>
            <a v-if="!isLogged" href="/login" class="button">Log in</a>
            <div v-else>
                <button class="nav-show-settings" @click="showSettingsBox = !showSettingsBox">{{ this.userName }}
                    <i class="bi bi-caret-down"></i>
                </button>
                <div v-if="showSettingsBox" class="nav-settings-box animate__animated animate__fadeIn animate__faster">
                    <p @click="visibleModal = !visibleModal">Change password</p>
                    <p v-if="!subscription" @click="purchaseSubscription">Purchase subscription</p>
                    <p v-else @click="cancelSubscription">Cancel subscription</p>
                    <p @click="logout">Log out</p>
                </div>
            </div>
        </div>
    </div>

    <div v-else>
        <img class="main-logo" src="@/assets/images/logo.jpg" alt="logo">
        <div class="main-nav-box">
            <h2>
                <RouterLink
                    to="/collections"
                    activeClass="active-link"
                    exactActiveClass="nonactive-link">
                    Collections
                </RouterLink>
            </h2>
            <h2>
                <RouterLink
                    to="/goddesses"
                    activeClass="active-link"
                    exactActiveClass="nonactive-link">
                    Goddesses
                </RouterLink>
            </h2>
            <h2>
                <RouterLink
                    to="/contactme"
                    activeClass="active-link"
                    exactActiveClass="nonactive-link">
                    Contact me
                </RouterLink>
            </h2>
            <div class="nav-right-buttons-box" style="top: 45px;">
                <button class="search-button button" @click="redirectSearch"><i class="bi bi-search"></i></button>
                <a v-if="!isLogged" href="/login" class="main-login-button">Log in</a>
                <div v-else>
                    <button class="nav-show-settings" @click="showSettingsBox = !showSettingsBox">{{ this.userName }}
                        <i class="bi bi-caret-down"></i>
                    </button>
                    <div v-if="showSettingsBox"
                         class="nav-settings-box animate__animated animate__fadeIn animate__faster">
                        <p @click="visibleModal = !visibleModal">Change password</p>
                        <div v-if="!subscriptionHide">
                            <p v-if="!subscription" @click="purchaseSubscription">Purchase subscription</p>
                            <p v-else @click="cancelSubscription">Cancel subscription</p>
                        </div>
                        <p @click="logout">Log out</p>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <modal v-model:visible="visibleModal"></modal>
</template>

<script>
import Modal from '@/components/ChangePassword.vue';
import Swal from 'sweetalert2';
import router from "@/router";
import {loggedUser} from "@/plugins/common/user";
import {cancelSubscription} from "@/plugins/requests";


export default {
    name: 'Navigation',
    data() {
        return {
            isLogged: false,
            userName: 'User',
            subscription: false,
            subscriptionHide: false,
            showSettingsBox: false,
            visibleModal: false,
            swal: Swal.mixin({
                confirmButtonText: 'Confirm',
                showCloseButton: true,
                customClass: {
                    confirmButton: 'button',
                },
                background: '#1E1E1E',
                color: '#FFFFFF',
                showClass: {
                    popup: `
                    animate__animated
                    animate__zoomIn
                    animate__faster
                    `
                },
            }),
            toast: Swal.mixin({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                background: '#1E1E1E',
                color: '#FFFFFF',
                showClass: {
                    popup: `
                    animate__animated
                    animate__slideInRight
                    animate__faster
                    `
                },
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            })
        }
    },
    components: {
        Modal
    },
    computed: {
        isActiveGoddesses() {
            return this.$route.path.startsWith('/goddesses');
        },
        isActiveCollections() {
            return this.$route.path.startsWith('/collections');
        },
        isLoggedIn() {
            return this.$root.isLoggedIn.value;
        },
    },
    watch: {
        isLoggedIn(newValue) {
            if (newValue) {
                this.isUserLogged();
            }
        },
    },
    mounted() {
        this.isUserLogged();
    },
    methods: {
        isUserLogged() {
            const item = localStorage.getItem('token');
            if (item !== null) {
                loggedUser().then(response => {
                    if (response.success) {
                        this.isLogged = true;
                        this.userName = response.user.email;
                        if (response.user.subscription) {
                            if (response.subscription.type === 'one-time') {
                                this.subscriptionHide = true;
                            }else{
                                this.subscriptionHide = false;
                                this.subscription = response.user.subscription;
                            }
                        }

                    } else {
                        this.isLogged = false;
                    }
                }).catch(e => {
                    this.isLogged = false;
                    console.log(e);
                });
            } else {
                this.isLogged = false;
                this.userName = '';
                this.subscription = false;
            }
        },
        logout() {
            const item = localStorage.getItem('token');
            if (item !== null) {
                localStorage.removeItem('token');
            }
            router.go();
        },
        cancelSubscription() {
            this.swal.fire({
                title: 'Cancel subscription',
                text: 'Access to all photos will be cancelled immediately'
            }).then((result) => {
                if (result.isConfirmed) {
                    cancelSubscription().then(response => {
                        if (response.success) {
                            this.showSettingsBox = false;
                            this.subscription = false;
                            this.subscriptionHide = false;
                            this.toast.fire({
                                icon: "success",
                                title: "Subscription is cancelled successfully!"
                            });
                        } else {
                            this.toast.fire({
                                icon: "error",
                                title: response.errorMsg
                            });
                        }
                    }).catch(e => {
                        console.log(e);
                    });
                }
            });
        },
        purchaseSubscription() {
            this.showSettingsBox = false;
            router.push('/subscription');
        },
        redirectSearch() {
            router.push('/search');
        }
    }
}
</script>

<style scoped>
.search-button {
    padding: 10px 13px;
    margin-right: 30px;
}

.nav-right-buttons-box {
    position: absolute;
    right: 0;
    display: flex;
    align-items: baseline;
}

.nav-settings-box {
    top: 60px;
}

.nav-settings-box p {
    margin: 20px;
    cursor: pointer;
}

.nav-show-settings {
    position: unset;
}

.main-login-button {
    position: unset;
}
</style>