<template>
    <div class="nav-box">
        <img src="@/assets/images/logo.jpg" alt="logo">
        <RouterLink
            to="/admin/goddesses"
            activeClass="active-link"
            exactActiveClass="nonactive-link">
            <p>Portfolio</p>
        </RouterLink>
        <RouterLink
            to="/admin/users"
            activeClass="active-link"
            exactActiveClass="nonactive-link">
            <p>Users</p>
        </RouterLink>
        <RouterLink
            to="/admin/settings"
            activeClass="active-link"
            exactActiveClass="nonactive-link">
            <p>Main settings</p>
        </RouterLink>
        <button class="nav-show-settings" @click="showSettingsBox = !showSettingsBox">Administrator <i
            class="bi bi-caret-down"></i></button>
        <div v-if="showSettingsBox" class="nav-settings-box animate__animated animate__fadeIn animate__faster">
            <p @click="visibleModal = !visibleModal">Change password</p>
            <p @click="logout">Log out</p>
        </div>
    </div>

    <modal v-model:visible="visibleModal"></modal>

</template>

<script>
import Modal from '@/components/ChangePassword.vue';
import router from "@/router";

export default {
    name: 'Admin navigation',
    components: {
        Modal
    },
    data() {
        return {
            showSettingsBox: false,
            visibleModal: false,
        }
    },
    methods: {
        logout(){
            const item = localStorage.getItem('token');
            if (item !== null) {
                localStorage.removeItem('token');
            }
            router.push('/');
        }
    }
}
</script>